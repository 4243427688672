.mdl-demo {
    text-align: start;
    border-top: none;
}

.mdl-demo .dropdown {
    border: none;
    border-style: none;
    padding-top: 5px;
    margin-top: 10px;
    width: 100%;
}

.mdl-demo .dropdown-trigger {
    min-width: 500px;
    width: 100%;
    min-height: 60px;
    border-style: none;
    border-radius: 5px;
    border-width: 0;
}

.mdl-demo .dropdown .dropdown-trigger > span{
    border-style: none;
    border-radius: 10px;
    border-width: 0;
    text-decoration: none;
}

.mdl-demo .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555;
}

.mdl-demo .tag-list .tag-item {
    margin-top: 12px;
}

/* Related to the each line on the dropdown */
.mdl-demo .dropdown-content {
    min-width: 500px;
    width: 100%;
}

.mdl-demo .toggle {
    font: normal normal normal 18px/1 "Material Icons";
    color: #555;
    white-space: pre;
    margin-right: 4px;
}