.NavigationItems {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  flex-flow: row;
  border-bottom: none;

  & > Button {
    margin: 8px;
  }

  .Buttonsignup {
    @media (max-width: 720px) {
      display: none;
    }
  }

  .MenuMobile {
    width: 100%;
    padding: 0 0;

    @media (min-width: 720px) {
      display: none;
    }
  }
}


.NavigationItem {
  width: auto;
  margin-left: 15px;
  display: flex;
  align-items: center;
  text-align: left;
  letter-spacing: 0px;
  

  & > a {
    color: inherit;
    height: 100%;
    padding: 20px;
    padding-bottom: 10px;
    position: relative;
    border-color: #514fff;
    border-style: solid;
    border-width: 3px;
    border-radius: 25px;


    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      text-decoration: none;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      &:after {
        width: calc(100% - 10px);
      }
    }

    &.active {
      color: #000; 
      border-style: solid;
      border-width: 3px;
      border-radius: 25px;
      border-color: #FBA661;
      background-color: #eee;

      &:after {
        background-color: #1890ff;
      }
    }
    &:hover {
      border-color: #FBA661;
      border-style: solid;
      border-width: 3px;
      border-radius: 25px;
    }
  }

  &.toggle-button {
    margin-left: 20px;
    position: relative;

    .buttton-icon-user {
      width: 60px;
      padding: 10px 0px;
      background-color: #1890ff;
      border: none;
      border-radius: 4px;

      .icon-user {
        width: 26px;
      }
    }
  }

  a {
    width: 100%;
    padding: 10px 20px;
    display: block;
    box-sizing: border-box;
    letter-spacing: 0px;
    text-decoration: none;
    text-align: left;
    color: #74809d;
    cursor: pointer;
  }
}

.toggleMenu {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 4px #1890ff;
  top: 80px;
  right: 0;
  position: absolute;

  &:after {
    width: 0;
    height: 0;
    top: -10px;
    right: 20px;
    display: block;
    border-top: solid 0 transparent;
    border-right: solid 10px transparent;
    border-bottom: solid 10px #fff;
    border-left: solid 10px transparent;
    content: '';
    position: absolute;
  }

  & > ul {
    min-width: 160px;
    margin: 0;
    padding: 0;

    li {
      a {
        padding: 10px 20px;
      }
    }
  }
}
