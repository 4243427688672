h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
div,
.ant-modal-title,
.ant-checkbox-wrapper {
  color: #000;
}

.switch-wrapper,
.ant-table-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  transition-timing-function: ease-in;
  width: 100%;
}

.ant-message {
  z-index: 9999;
}

.ant-popover {
  z-index: 1061;
}

.ant-select-dropdown-menu-item {
  white-space: pre-wrap;
}

.ant-menu-submenu-arrow{
  display: none!important;
}

.ant-col {
  width: 100%;
}

.report-style-class {
    height: 100%;
}